<template>
  <div class="d-flex justify-content-center align-items-center" style="min-height:100svh">
    <b-card
      style="max-width: 40rem;"
      class="w-100 p-5 shadow-sm"
    >
    <img alt="logo" src="../../assets/launchyou.jpg" class="mb-5 img-fluid">
      
      
      <h6>Enter the email address below.</h6>
      
      <div>
        <b-alert show variant="danger" v-if="showError">{{ errorMessage }}</b-alert>
        <b-alert show variant="warning" v-if="showStatus">{{ status }}</b-alert>
      </div>
      <b-form @submit="onSubmit">
        <b-form-group
          label="Email address:"
          label-for="email"
        >
          <b-form-input
            v-model="form.email"
            type="email"
            placeholder="Enter email"
          ></b-form-input>
          <b-alert show variant="warning" v-if="errors.email">{{ errors.email }}</b-alert>
        </b-form-group>

        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
      
      <div><a href="/">Login</a></div>
    </b-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      form: {
        email: '',
      },
      showError: false,
      errorMessage: '',
        showStatus: false,
        status: false,
      errors: {email: null},
    }
  },
  methods: {
    ...mapActions(['forgotPassword']),
    onSubmit(event) {
      event.preventDefault()
      this.showError = false;
      let email = this.form.email

      this.errors = {};

      if (email === '') {
        this.errors.email = 'Email is required.';
      }

      if (Object.keys(this.errors).length > 0) {
        return false;
      }

      this.forgotPassword({email})
          .then(response => {
            this.showStatus = response.status
            this.status = 'If the email address provided exists, you will shortly receive an email with the '
              + ' instructions to change your password'
        })
        .catch(error => {
          this.errorMessage = error.response.data.error
        });
    }
  }
}
</script>

